<template>
  <v-app id="inspire">
    <v-dialog
      v-model="showProfile"
      max-width="1000px"
      scrollable
      :fullscreen="full"
    >
      <profile ref="profile" @submit="close" @update="updateAvatar" />
    </v-dialog>
    <app-batch-import ref="batchImport" />
    <juvenile-billfish-form
      :open="openJuvenile"
      @update:open="(value) => (openJuvenile = value)"
      set-user
    />
    <reward-form ref="rewardForm" />
    <record-fish ref="recordFish" @done="askForRewards" />
    <Header ref="header" v-model="expandOnHover" @profile="openProfile" />
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-main>
      <record-entry @open="openDialog" />
      <pending-locations-banner />
      <pending-tournaments-banner />
      <duplicated-releases-banner />
      <v-container fluid class="page-wrapper">
        <router-view />
      </v-container>
    </v-main>
    <Sidebar ref="sidebar" :expand-on-hover.sync="expandOnHover" />

    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Footer from './footer/Footer';
import { mapState, mapMutations, mapActions } from 'vuex';
import Profile from '@/views/users/Profile.vue';
import RecordFish from '@/views/users/forms/RecordFish.vue';
import RecordEntry from '../../components/appComponents/RecordEntry.vue';
import { notifyMessage } from '../../helpers/handler';
import PendingLocationsBanner from '../../components/appComponents/PendingLocationsBanner.vue';
import BatchImport from '../../views/users/forms/BatchImport.vue';
import JuvenileForm from '../../views/users/forms/sections/JuvenileBillfishForm.vue';
import RewardForm from '../../views/users/forms/sections/RewardForm.vue';
import DuplicatedReleasesBanner from '../../components/appComponents/DuplicatedReleasesBanner.vue';
import PendingTournamentsBanner from '../../components/appComponents/PendingTournamentsBanner.vue';
export default {
  name: 'Layout',
  components: {
    Header,
    Sidebar,
    Footer,
    Profile,
    RecordFish,
    RecordEntry,
    PendingLocationsBanner,
    PendingTournamentsBanner,
    DuplicatedReleasesBanner,
    'app-batch-import': BatchImport,
    'juvenile-billfish-form': JuvenileForm,
    RewardForm
  },

  props: {
    source: String
  },
  data: () => ({
    expandOnHover: false,
    showProfile: false,
    openJuvenile: false,
    overlay: false
  }),
  computed: {
    ...mapState(['Customizer_drawer']),
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  async mounted() {
    const response = await this.getAvatar();
    const avatar =
      response?.data?.docs && JSON.parse(response.data.docs)?.image;
    this.updateAvatar(avatar);
  },
  methods: {
    ...mapMutations({
      setCustomizerDrawer: 'SET_CUSTOMIZER_DRAWER'
    }),
    ...mapActions('users', ['saveUserInfo', 'getUser']),
    ...mapActions('entry', ['getAvatar']),
    ...mapActions('entry', [
      'addTag',
      'editTag',
      'addRelease',
      'editRelease',
      'addRecapture',
      'editRecapture'
    ]),
    async close(data) {
      this.showProfile = false;
      if (data) {
        const response = await this.saveUserInfo(data);
        notifyMessage(response ? 'Profile Updated' : 'Something went wrong');
      }
    },
    openProfile() {
      this.showProfile = true;
      this.$refs.profile && this.$refs.profile.init();
    },
    async openDialog(type) {
      if (type === 'Juvenile') {
        this.openJuvenile = true;
        this.overlay = false;
        return;
      }

      if (type === 'TagBatch') {
        this.$refs.batchImport && (this.$refs.batchImport.showDialog = true);
        return;
      }

      this.onRecord = true;
      const data = { userId: null, record: null };
      this.$refs.recordFish && this.$refs.recordFish.init(data, type);
    },
    updateAvatar(img) {
      this.$refs.header && this.$refs.header.updateAvatar(img);
      this.$refs.sidebar && this.$refs.sidebar.updateAvatar(img);
    },
    async askForRewards({ recordType, crew, recordId }) {
      if (!/recapture/i.exec(recordType)) {
        return;
      }

      if (this.$refs.rewardForm) {
        this.overlay = true;
        await this.$refs.rewardForm.init(recordId, crew);
        this.overlay = false;
      }
    }
  }
};
</script>
